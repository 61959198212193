<template>

 <div class="col-12"> 
 <h2>積雪IoT</h2>
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>センサ名</th>
            </tr>
          </thead>
          
          <tbody>
                    
              <tr v-for="(device, key) in devices" :key="key">
                <td>
                <router-link :to="{path: `/snow/${device.node_id}`}">
                 <span >  {{ device.node_id }}</span >
                </router-link>
                </td>
                <td>
                 <span >{{ device.name}}</span >
                </td>
            </tr>
          </tbody>
        </table>
      </div>
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";
import axios from 'axios';

export default defineComponent({
  name: "Snow",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
  devices: [] 
  } },
  computed: {},
  mounted : function(){
     this.$emit('show-header');
       axios.get("/api/v2/devices/?sensor_type=34"
        ,
        { withCredentials: true }
        )
       .then( function(json) {
         
           if( json.data){
                this.devices  = json.data.data;
                return true;
            }
            return false; 
      }.bind(this));
    

   },
  methods: {
    }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}